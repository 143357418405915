import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import React, { useState, useRef, useEffect, useMemo } from 'react';

import { MaxWidth } from '../../components/MaxWidth';
import {
  HandCheck,
  Ticket,
  People,
  Trophy,
  Gift,
  Food,
  Drink,
  ThreePeople,
  Shop,
  Themes,
  Joker,
  Price,
  Desibled,
  Drive,
  DCcomics,
  Bats,
} from '../../components/Icons';

const getIcon = (iconName) => {
  switch (iconName) {
    case 'handcheck':
      return <HandCheck />;
    case 'ticket':
      return <Ticket />;
    case 'groupe':
      return <People />;
    case 'coupe':
      return <Trophy />;
    case 'gift':
      return <Gift />;
    case 'food':
      return <Food />;
    case 'drink':
      return <Drink />;
    case 'people':
      return <ThreePeople />;
    case 'shop':
      return <Shop />;
    case 'themes':
      return <Themes />;
    case 'joker':
      return <Joker />;
    case 'price':
      return <Price />;
    case 'desibled':
      return <Desibled />;
    case 'drive':
      return <Drive />;
    case 'dccomics':
      return <DCcomics />;
    case 'bats':
      return <Bats />;
    default:
      return null;
  }
};

const WhyUs = ({ slice }) => {
  const sectionRef = useRef(null);
  const [active, setActive] = useState(0);
  const [height, setHeight] = useState(0);

  const handleActive = (index) => {
    setActive(index);
  };

  useEffect(() => {
    const handleResize = () => {
      if (sectionRef.current) {
        setHeight(
          Math.max(
            ...Array.from(
              sectionRef.current.querySelectorAll('.why-us__item__description')
            ).map((item) => {
              return item.offsetHeight;
            })
          )
        );
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const displayDescriptionBlock = useMemo(() => {
    return slice.items.some((item) => {
      return item.description && prismicH.asText(item.description);
    });
  }, [slice.items]);

  return (
    <div className="why-us" ref={sectionRef}>
      <MaxWidth>
        <div className="why-us__container">
          <div className="why-us__content">
            <div className="why-us__content__text">
              <div className="why-us__content__text__title">
                {slice.primary.title ? (
                  <h2>{prismicH.asText(slice.primary.title)}</h2>
                ) : null}
              </div>
              <div className="why-us__content__text__subtitle">
                {slice.primary.subtitle ? (
                  <PrismicRichText field={slice.primary.subtitle} />
                ) : null}
              </div>
            </div>

            <div className="why-us__list">
              {slice.items.map((item, index) => {
                return (
                  <div
                    className="why-us__item"
                    key={index}
                    onMouseEnter={() => handleActive(index)}
                  >
                    <div className="why-us__item__icon">
                      {item.priority_icon?.url ? (
                        <img
                          src={item.priority_icon.url}
                          alt={item.priority_icon.alt || ''}
                          draggable={false}
                        />
                      ) : item.icon ? (
                        getIcon(item.icon)
                      ) : null}
                    </div>

                    {item.iconText ? (
                      <div className="why-us__item__title">
                        <h3>{prismicH.asText(item.iconText)}</h3>
                      </div>
                    ) : null}

                    {item.description ? (
                      <div className="why-us__item__text">
                        <PrismicRichText field={item.description} />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>

            {displayDescriptionBlock ? (
              <div
                className="why-us__item__description__block"
                style={{
                  height: `${height}px`,
                  ['--triangle-left']: `${Math.round(
                    ((active + 1) * 0.25 - 0.25 / 2) * 100
                  )}%`,
                }}
              >
                {slice.items.map((item, index) => {
                  if (item.description) {
                    return (
                      <div
                        key={index}
                        className={`why-us__item__description ${
                          active === index
                            ? 'why-us__item__description--active'
                            : ''
                        }`}
                      >
                        <PrismicRichText field={item.description} />
                      </div>
                    );
                  }

                  return null;
                })}
              </div>
            ) : null}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default WhyUs;
