import { PrismicRichText } from '@prismicio/react';
import React from 'react';

import * as prismicH from '@prismicio/helpers';

import { Title } from '../../components/Title';
import { Button } from '../../components/Button';
import { MaxWidth } from '../../components/MaxWidth';

import classnames from 'classnames';
import { isLink } from '../../helpers/is-link';

const Prices = ({ slice }) => {
  return (
    <div className="prices" id={slice.primary.anchor || null}>
      <MaxWidth>
        <div className="prices__container">
          {prismicH.asText(slice.primary.title) ? (
            <Title
              size="medium"
              color={slice.primary.color}
              borderTop
              titleUpperCase
              align="center"
            >
              <PrismicRichText field={slice.primary.title} />
            </Title>
          ) : null}

          <div
            className={classnames(
              'prices__content',
              `prices__content--${slice.items.length}`
            )}
          >
            {slice.items.map((item, index) => {
              return (
                <div className="prices__item" key={index}>
                  {item.bandTop ? (
                    <div className="prices__item__band">Best seller</div>
                  ) : null}

                  <div className="prices__item__title">
                    {item.title ? <PrismicRichText field={item.title} /> : null}
                  </div>

                  <div className="prices__item__subtitle">
                    {item.subTitle ? (
                      <PrismicRichText field={item.subTitle} />
                    ) : null}
                  </div>

                  <div className="prices__item__price">
                    {item.price ? <PrismicRichText field={item.price} /> : null}
                  </div>

                  <div className="prices__item__price__text">
                    {item.priceText ? (
                      <PrismicRichText field={item.priceText} />
                    ) : null}
                  </div>

                  <div className="prices__item__price__list">
                    {item.listText ? (
                      <PrismicRichText field={item.listText} />
                    ) : null}
                  </div>

                  {isLink(item.buttonLink) ? (
                    <div className="prices__item__button">
                      <Button field={item.buttonLink}>
                        {prismicH.asText(item.buttonTitle)}
                      </Button>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>

        <div className="prices__list">
          {slice.primary.list ? (
            <PrismicRichText field={slice.primary.list} />
          ) : null}
        </div>

        {isLink(slice.primary.buttonLink) ? (
          <div className="prices__cta">
            <Button field={slice.primary.buttonLink}>
              {prismicH.asText(slice.primary.buttonTitle)}
            </Button>
          </div>
        ) : null}

        <img
          className="prices__illustration"
          src="/illustrations/batman.png"
          alt="robin character"
          width="346"
          height="484"
        />
      </MaxWidth>
    </div>
  );
};

export default Prices;
